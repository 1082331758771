<template>
  <div id="app">
    <router-view/>
    <Loading v-show="LOADING"></Loading>
  </div>
</template>

<script>
  import {mapState} from 'vuex'
  import Loading from '@/components/Loading.vue'
  export default {
    computed: {
      ...mapState(['LOADING'])
    },
    name: 'App',
    components: {Loading}
  }
</script>

<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.m-page, .m-footer {
  min-width: 320px;
  max-width: 640px;
  margin: 0 auto;
}

// 禁止图片被选中
.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}
</style>
